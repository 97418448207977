import React, { useEffect, useState } from 'react'
import { useNavigate, Link, useParams, NavLink, useLocation } from 'react-router-dom'
import '../Style.css'
import '../styles/material-dashboard.css'
import '../styles/material-dashboard.min.css'
import '../styles/material-dashboard-rtl.css'
import logo from "../images/fav_logo.png"
import { Navigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faPhone, faGauge, faArrowRightFromBracket, faStar, faUser, faUsers, faRoad, faSearch, faChartBar, faCalendarWeek, faIdCard } from '@fortawesome/free-solid-svg-icons'
import Checklogin from './pages/Checklogin'
import axios from 'axios'
const usericon = <FontAwesomeIcon icon={faUser} />
const usersicon = <FontAwesomeIcon icon={faUsers} />
const cars = <FontAwesomeIcon icon={faCar} />
const road = <FontAwesomeIcon icon={faRoad} />
const search = <FontAwesomeIcon icon={faSearch} />
const phone = <FontAwesomeIcon icon={faPhone} />
const graph = <FontAwesomeIcon icon={faChartBar} />
const calender = <FontAwesomeIcon icon={faCalendarWeek} />
const idcard = <FontAwesomeIcon icon={faIdCard} />
const dash = <FontAwesomeIcon icon={faGauge} />
const logout = <FontAwesomeIcon icon={faArrowRightFromBracket} />

const user = sessionStorage.getItem('userid');
const branch = sessionStorage.getItem('branch');
const user_role = sessionStorage.getItem('role');

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export default function SidebarMenu() {
  let navigate = useNavigate();
  const location = useLocation();
const [oldvehicle, setoldvehicle] = useState(0)
  const API_PATH=`${SERVER_URL}vehicle_actions.php`
  useEffect(()=>{
    axios({
        method:'post',
        url:API_PATH,
        data:{user:user,type:'old'}
    })
    .then((res)=>{
      console.log(res.data)
      setoldvehicle(res.data.length)
     
    })
    .catch((err)=>console.log(err.message))

  },[])

  const oldvehcount=<><p className="warncount" style={{color:'#fff',background:'red',borderRadius:'50px'}}>{oldvehicle}</p><p style={{margin:'0px'}}>{cars}</p></>
  
  const [driverleavecount, setdriverleavecount] = useState(0)
  const API_PATH2 = `${SERVER_URL}leaves.php`;
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH2}`,
      data: { type: 'normal' }
    }).then((res) => {
      console.log('here',res.data.length)
      setdriverleavecount(res.data.length)

    })
      .catch((err) => console.log(err))

  }, [])

  const leavecount=<><p className="warncount" style={{color:'#fff',background:'red',borderRadius:'50px'}}>{driverleavecount}</p><p style={{margin:'0px'}}>{calender}</p></>
  
  const [staffleavecount, setstaffleavecount] = useState(0)
  const API_PATH3 = `${SERVER_URL}staff.php`;
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH3}`,
      data: { branch: branch, user_role: user_role,status:1,type:'leave_list' }
    }).then((res) => {
      console.log(res.data)
      
      setstaffleavecount(res.data.length)
    
    })
      .catch((err) => console.log(err))

  }, [])

  const leavecountstaff=<><p className="warncount" style={{color:'#fff',background:'red',borderRadius:'50px'}}>{staffleavecount}</p><p style={{margin:'0px'}}>{calender}</p></>
  
 return (
    <div className="sidebar">
      <Checklogin />
      <div className="logo">fleetp
        {/* <img src={logo} id="icon1" alt="User Icon" /> */}
      </div>
      <>

      {user_role == 1 && (user==13 || user==18 || user==31)? (
          <Navigation
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            navigate(`${itemId}`)
          }}
          activeStyles={{ display: "none", fontWeight: "bold", color: "red" }}
          items={[
            {
              title: 'Dashboard',
              itemId: '/dashboard',
              elemBefore: () => dash

            },
            
            {
              title: 'Users',
              itemId: '#1',
              elemBefore: () => usericon,
              subNav: [
                {
                  title: 'Add User',
                  itemId: '/add-user',

                },
                {
                  title: 'Users List',
                  itemId: '/user-list',

                },
              ],
            },

            {
              title: 'Drivers',
              itemId: '#',
              elemBefore: () => usersicon,
              subNav: [
                {
                  title: 'Add Driver',
                  itemId: '/add-driver',

                },

                {
                  title: 'Drivers List',
                  itemId: '/drivers',

                },


                {
                  title: 'Offloaded Drivers',
                  itemId: '/ex-drivers',

                },
                {
                  title: 'Drivers Join date',
                  itemId: '/new-joiners',

                }
                ,
                {
                  title: 'Validate Driver Details',
                  itemId: '/validate-driver'

                }
              
              ],
            },
            
            {
              title: 'Vehicles',
              itemId: '#2',
              elemBefore: () => cars,
              subNav: [
                {
                  title: 'Add Vehicle',
                  itemId: '/add-vehicle',
                },
                
                {
                  title: 'All Vehicle List',
                  itemId: '/vehicles',
                },
                {
                  title: 'Add Vehicle Type',
                  itemId: '/add-vehicle-type',
                },
                {
                  title: 'Vehicle Type List',
                  itemId: '/vehicle-type-list',
                },
                {
                  title: 'Offloaded Vehicles',
                  itemId: '/off-vehicle',
                },
              ],
            },
            {
              title: `Aging Vehicles`,
              itemId: '/old-vehicles',
              elemBefore: () =>oldvehcount,
              
              

            },
            {
              title: 'Fuel',
              itemId: '#3',
              elemBefore: () => cars,
              subNav: [
                {
                  title: 'Fuel Report',
                  itemId: '/fuel-report2',
                },
                {
                  title: 'Fuel Overused',
                  itemId: '/fuel-overused2',
                },
                {
                  title: 'Driver VS Fuel',
                  itemId: '/fuel-driver2',
                },
                {
                  title: 'Km - Fuel - Driver',
                  itemId: '/km-fuel-driver2',
                }
              ],
            },
            {
              title: 'KM Report',
              itemId: '/km-report2',
              elemBefore: () => road

            },
            {
              title: 'Search vehicle',
              itemId: '/search-vehicle2',
              elemBefore: () => search

            },
            {
              title: 'POD',
              itemId: '#4',
              elemBefore: () => usersicon,
              subNav: [
                {
                  title: 'Upload POD',
                  itemId: '/upload-pod',
                },
                {
                  title: 'Retrieve POD',
                  itemId: '/retrieve-pod',
                }
              ],
            },
            {
              title: 'Salik Report',
              itemId: '/salik-report',
              elemBefore: () => road

            },
            {
              title: 'Telephone Report',
              itemId: '/telephone-report',
              elemBefore: () => phone

            },
            {
              title: 'Analytics',
              itemId: '/analytics',
              elemBefore: () => graph

            },
            {
              title: 'Leave Applications',
              itemId: '/leave-applications',
              elemBefore: () => leavecount

            },
            {
              title: 'Visa Applications',
              itemId: '/visa-applications',
              elemBefore: () => idcard

            },
            {
              title: 'Admin Dashboard',
              itemId: '/admin-dashboard',

              elemBefore: () => dash,


            },

            {
              title: 'Staffs',
              itemId: '#5',
              elemBefore: () => usersicon,
              subNav: [
                {
                  title: 'Add Staff',
                  itemId: '/add-staff',
                },
                {
                  title: 'Staff List',
                  itemId: '/staff-list',
                }
              ],
            },
            {
              title: 'Staff Leaves',
              itemId: '/staff-leave-applications',

              elemBefore: () => leavecountstaff

            },
            {
              title: 'Log Out',
              itemId: '/logout',
              elemBefore: () => logout

            },
          ]}
        />
        ): (

null
      )}

{user_role == 1 && user==14 ? (
          <Navigation
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            navigate(`${itemId}`)
          }}
          activeStyles={{ display: "none", fontWeight: "bold", color: "red" }}
          items={[
            {
              title: 'Dashboard',
              itemId: '/dashboard',
              elemBefore: () => dash

            },
            
            {
              title: 'Users',
              itemId: '#1',
              elemBefore: () => usericon,
              subNav: [
                {
                  title: 'Add User',
                  itemId: '/add-user',

                },
                {
                  title: 'Users List',
                  itemId: '/user-list',

                },
              ],
            },

            {
              title: 'Drivers',
              itemId: '#',
              elemBefore: () => usersicon,
              subNav: [
                {
                  title: 'Add Driver',
                  itemId: '/add-driver',

                },

                {
                  title: 'Drivers List',
                  itemId: '/drivers',

                },


                {
                  title: 'Offloaded Drivers',
                  itemId: '/ex-drivers',

                },
                {
                  title: 'Drivers Join date',
                  itemId: '/new-joiners'

                }
              ],
            },
            
            {
              title: 'Vehicles',
              itemId: '#2',
              elemBefore: () => cars,
              subNav: [
                {
                  title: 'Add Vehicle',
                  itemId: '/add-vehicle',
                },
                
                {
                  title: 'All Vehicle List',
                  itemId: '/vehicles',
                },
                {
                  title: 'Add Vehicle Type',
                  itemId: '/add-vehicle-type',
                },
                {
                  title: 'Vehicle Type List',
                  itemId: '/vehicle-type-list',
                },
                {
                  title: 'Offloaded Vehicles',
                  itemId: '/off-vehicle',
                },
              ],
            },
            {
              title: `Aging Vehicles`,
              itemId: '/old-vehicles',
              elemBefore: () =>oldvehcount,
              
              

            },
            {
              title: 'Fuel',
              itemId: '#3',
              elemBefore: () => cars,
              subNav: [
                {
                  title: 'Fuel Report',
                  itemId: '/fuel-report2',
                },
                {
                  title: 'Fuel Overused',
                  itemId: '/fuel-overused2',
                },
                {
                  title: 'Driver VS Fuel',
                  itemId: '/fuel-driver2',
                },
                {
                  title: 'Km - Fuel - Driver',
                  itemId: '/km-fuel-driver2',
                }
              ],
            },
            {
              title: 'KM Report',
              itemId: '/km-report2',
              elemBefore: () => road

            },
            {
              title: 'Search vehicle',
              itemId: '/search-vehicle2',
              elemBefore: () => search

            },
            {
              title: 'POD',
              itemId: '#4',
              elemBefore: () => usersicon,
              subNav: [
                {
                  title: 'Upload POD',
                  itemId: '/upload-pod',
                },
                {
                  title: 'Retrieve POD',
                  itemId: '/retrieve-pod',
                }
              ],
            },
            {
              title: 'Salik Report',
              itemId: '/salik-report',
              elemBefore: () => road

            },
            {
              title: 'Telephone Report',
              itemId: '/telephone-report',
              elemBefore: () => phone

            },
            {
              title: 'Analytics',
              itemId: '/analytics',
              elemBefore: () => graph

            },
            
           
            {
              title: 'Admin Dashboard',
              itemId: '/admin-dashboard',

              elemBefore: () => dash,


            },

            {
              title: 'Staffs',
              itemId: '#5',
              elemBefore: () => usersicon,
              subNav: [
                {
                  title: 'Add Staff',
                  itemId: '/add-staff',
                },
                {
                  title: 'Staff List',
                  itemId: '/staff-list',
                }
              ],
            },
            
            {
              title: 'Log Out',
              itemId: '/logout',
              elemBefore: () => logout

            },
          ]}
        />
        ): (

null
      )}

        {user_role == 1 &&(user!=13 && user!=18 && user!=14 && user!=31) ? (
          <Navigation
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            navigate(`${itemId}`)
          }}
          activeStyles={{ display: "none", fontWeight: "bold", color: "red" }}
          items={[
            {
              title: 'Dashboard',
              itemId: '/dashboard',
              elemBefore: () => dash

            },
            {
              title: 'Users',
              itemId: '#1',
              elemBefore: () => usericon,
              subNav: [
                {
                  title: 'Add User',
                  itemId: '/add-user',

                },
                {
                  title: 'Users List',
                  itemId: '/user-list',

                },
              ],
            },

            {
              title: 'Drivers',
              itemId: '#',
              elemBefore: () => usersicon,
              subNav: [
                {
                  title: 'Add Driver',
                  itemId: '/add-driver',

                },

                {
                  title: 'Drivers List',
                  itemId: '/drivers',

                },


                {
                  title: 'Offloaded Drivers',
                  itemId: '/ex-drivers',

                },
                {
                  title: 'Drivers Join date',
                  itemId: '/new-joiners'

                }
                ,
                {
                  title: 'Validate Driver Details',
                  itemId: '/validate-driver'

                }
              ],
            },
            
            {
              title: 'Vehicles',
              itemId: '#2',
              elemBefore: () => cars,
              subNav: [
                {
                  title: 'Add Vehicle',
                  itemId: '/add-vehicle',
                },
                
                {
                  title: 'All Vehicle List',
                  itemId: '/vehicles',
                },
                {
                  title: 'Add Vehicle Type',
                  itemId: '/add-vehicle-type',
                },
                {
                  title: 'Vehicle Type List',
                  itemId: '/vehicle-type-list',
                },
                {
                  title: 'Offloaded Vehicles',
                  itemId: '/off-vehicle',
                },
              ],
            },
            {
              title: `Aging Vehicles`,
              itemId: '/old-vehicles',
              elemBefore: () =>oldvehcount,
              
              

            },
            {
              title: 'Fuel',
              itemId: '#3',
              elemBefore: () => cars,
              subNav: [
                {
                  title: 'Fuel Report',
                  itemId: '/fuel-report2',
                },
                {
                  title: 'Fuel Overused',
                  itemId: '/fuel-overused2',
                },
                {
                  title: 'Driver VS Fuel',
                  itemId: '/fuel-driver2',
                },
                {
                  title: 'Km - Fuel - Driver',
                  itemId: '/km-fuel-driver2',
                }
              ],
            },
            {
              title: 'KM Report',
              itemId: '/km-report2',
              elemBefore: () => road

            },
            {
              title: 'Search vehicle',
              itemId: '/search-vehicle2',
              elemBefore: () => search

            },
            {
              title: 'POD',
              itemId: '#4',
              elemBefore: () => usersicon,
              subNav: [
                {
                  title: 'Upload POD',
                  itemId: '/upload-pod',
                },
                {
                  title: 'Retrieve POD',
                  itemId: '/retrieve-pod',
                }
              ],
            },
            {
              title: 'Salik Report',
              itemId: '/salik-report',
              elemBefore: () => road

            },
            {
              title: 'Telephone Report',
              itemId: '/telephone-report',
              elemBefore: () => phone

            },
            {
              title: 'Analytics',
              itemId: '/analytics',
              elemBefore: () => graph

            },
            
            
            {
              title: 'Log Out',
              itemId: '/logout',
              elemBefore: () => logout

            },
          ]}
        />
        ): (
null
          )}

          {user_role!=1?(
            <Navigation
            activeItemId={location.pathname}
            onSelect={({ itemId }) => {
              navigate(`${itemId}`)
            }}
            activeStyles={{ display: "none", fontWeight: "bold", color: "red" }}
            items={[
              {
                title: 'Dashboard',
                itemId: '/dashboard',
                elemBefore: () => dash
  
              },
            
  
              {
                title: 'Drivers',
                itemId: '#',
                elemBefore: () => usersicon,
                subNav: [
                  {
                    title: 'Add Driver',
                    itemId: '/add-driver',
  
                  },
  
                  {
                    title: 'Drivers List',
                    itemId: '/drivers',
  
                  },
  
                  ,
                  {
                    title: 'Validate Driver Details',
                    itemId: '/validate-driver'
  
                  }
                  
                ],
              },
              {
                title: 'Vehicles',
                itemId: '#2',
                elemBefore: () => cars,
                subNav: [
                  {
                    title: 'Add Vehicle',
                    itemId: '/add-vehicle',
                  },
                  {
                    title: 'My Vehicle List',
                    itemId: '/my-vehicles',
                  },
                  {
                    title: 'All Vehicle List',
                    itemId: '/vehicles',
                  },
                  
                  {
                    title: 'Offloaded Vehicles',
                    itemId: '/off-vehicle',
                  },
                ],
              },
              
              {
                title: 'Fuel',
                itemId: '#3',
                elemBefore: () => cars,
                subNav: [
                  {
                    title: 'Fuel Report',
                    itemId: '/fuel-report2',
                  },
                  {
                    title: 'Fuel Overused',
                    itemId: '/fuel-overused2',
                  },
                  {
                    title: 'Driver VS Fuel',
                    itemId: '/fuel-driver2',
                  },
                  {
                    title: 'Km - Fuel - Driver',
                    itemId: '/km-fuel-driver2',
                  }
                ],
              },
              {
                title: 'KM Report',
                itemId: '/km-report2',
                elemBefore: () => road
  
              },
              {
                title: 'Search vehicle',
                itemId: '/search-vehicle2',
                elemBefore: () => search
  
              },
              {
                title: 'POD',
                itemId: '#4',
                elemBefore: () => usersicon,
                subNav: [
                  {
                    title: 'Upload POD',
                    itemId: '/upload-pod',
                  },
                  {
                    title: 'Retrieve POD',
                    itemId: '/retrieve-pod',
                  }
                ],
              },
              {
                title: 'Log Out',
                itemId: '/logout',
                elemBefore: () => logout
  
              },
              
              
              
  
              
            ]}
          />
          ):null}
        
      </>
    </div>
  )
}
